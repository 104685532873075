import axios from "axios";
import React, { useState } from "react";

const Mapandform = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const changeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    console.log(user);
  };
  const postDataConfirmation = () => {
    alert("Thank You! We Have Recievd Your Request");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    try {
      axios.post(
        "https://eastechy-227ca-default-rtdb.firebaseio.com/register.json",
        user
      );
      postDataConfirmation();
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <>
      {/* contact section */}
      <section className="contact_section layout_padding" id="Contact-us-in">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 offset-md-1">
              <div className="form_container">
                <div className="heading_container">
                  <h2>Request A Call back</h2>
                </div>
                <form onSubmit={submitHandler}>
                  <div>
                    <input
                      type="text"
                      placeholder="Full Name "
                      name="name"
                      onChange={changeHandler}
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={changeHandler}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Phone number"
                      name="phoneNumber"
                      onChange={changeHandler}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="message-box"
                      placeholder="Message"
                      name="message"
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="d-flex ">
                    <button style={{ background: "#4b8ef1" }}>SEND</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 px-0">
              <div className="map_container">
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.422029635227!2d78.44131267498649!3d17.43950328345661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90c5079f2023%3A0x823b8b8832e1cdae!2sSatya%20Sai%20Apartments%2C%20Sanjeeva%20Reddy%20Nagar%20Rd%2C%20East%20Srinivas%20Nagar%20Colony%2C%20Srinivasa%20Nagar%2C%20Ameerpet%2C%20Hyderabad%2C%20Telangana%20500038%2C%20India!5e0!3m2!1sen!2sus!4v1708501678968!5m2!1sen!2sus"
                    width={600}
                    height={450}
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="eastechy-it-solutions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end contact section */}
    </>
  );
};

export default Mapandform;
