import React from "react";
import "./Services.css";
import logo from "../../../images/logo.png";
import { Link } from "react-router-dom";

const ServicesNavbar = () => {
  return (
    <>
      <header className="header_section">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <img
              src={logo}
              width={"90vh"}
              alt="eastechy-logo"
              className="eastechy-logo"
            />
            <a className="navbar-brand" href="/">
              <span className="anta-regular-main">EASTECHY</span>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className=""> </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  ">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a className="nav-link" href="#about">
                    {" "}
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/services" className="nav-link">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#Contact-us-in">
                    Contact Us
                  </a>
                </li>
              </ul>
              <div
                className="quote_btn-container"
                style={{ marginRight: "2px" }}
              >
                <a href="tel:6281967624" className="quote_btn">
                  Get A Quote
                </a>
              </div>{" "}
              <div className="quote_btn-container">
                <a href="tel:6281967624" className="quote_btn_callnow ">
                  Call Now
                </a>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default ServicesNavbar;
