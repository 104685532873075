import React from "react";
import eastechy from "../../images/logo.png";
const Footer = () => {
  const postDataConfirmation = () => {
    alert("Thank You! We Have Recievd Your Request");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="footer_container">
      {/* info section */}
      <section className="info_section ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 ">
              <div className="info_detail">
                <h4>
                  <img src={eastechy} style={{ width: "45px" }} />
                  EASTECHY
                </h4>
                <p style={{ textAlign: "justify", textIndent: "45px" }}>
                  "Eastechy IT Solutions is your premier tech partner, dedicated
                  to exceeding your expectations and providing unparalleled
                  customer service. With a focus on customer satisfaction, we're
                  committed to being here for you, always. At Eastechy IT
                  Solutions, excellence meets service, ensuring that your
                  success is our top priority."
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 mx-auto">
              <div className="info_link_box">
                <h4 className="Links_name">Links</h4>
                <div className="info_links">
                  <ul className="navbar-nav  ">
                    <li className="nav-item active">
                      <a className="nav-link" href="/">
                        Home <span className="sr-only">(current)</span>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a className="nav-link" href="#about">
                        {" "}
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Contact-us-in">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <h4>Subscribe</h4>
              <form action="#" onSubmit={postDataConfirmation}>
                <input type="text" placeholder="Enter email" name="email" />
                <button type="submit">Subscribe</button>
              </form>
            </div>
            <div className="col-md-6 col-lg-3 mb-0 ml-auto">
              <div className="info_contact">
                <h4>Address</h4>
                <div className="contact_link_box">
                  <a href="#Contact-us-in">
                    <i className="fa fa-map-marker" aria-hidden="true" />
                    <span>Location</span>
                    <br />
                    <p style={{ textAlign: "left" }}>
                      Satya Sai Apartments,Sanjeeva Reddy Nagar Rd, East
                      Srinivas Nagar Colony, Srinivasa Nagar, Ameerpet,
                      Hyderabad, Telangana 500038
                    </p>
                  </a>
                  <a href="tel:6281967624">
                    <i className="fa fa-phone" aria-hidden="true" />
                    <span>Call +91 6281967624</span>
                  </a>
                  <p>
                    <i className="fa fa-envelope" aria-hidden="true" />{" "}
                    <span>info@eastechy.com</span>
                  </p>
                </div>
              </div>
              <div className="info_social">
                <a href="https://www.facebook.com/people/Eastechy/61556924008631/">
                  <i className="fa fa-facebook" aria-hidden="true" />
                </a>
                {/*   <a href="">
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
                <a href="">
                  <i className="fa fa-linkedin" aria-hidden="true" />
                </a> */}
                <a href="https://www.instagram.com/eastechy/?igsh=YXBoazk1cjNlYTlu">
                  <i className="fa fa-instagram" aria-hidden="true" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end info section */}
      {/* footer section */}
      <footer className="footer_section">
        <div className="container">
          <p>
            © <span id="displayYear" /> All Rights Reserved By{" "}
            <a href="https://eastechy.com/">EasTechy IT Solutions</a>
          </p>
        </div>
      </footer>
      {/* footer section */}
    </div>
  );
};

export default Footer;
