import React from "react";
import ServicesNavbar from "./ServicesNavbar";
import Footer from "../../../Components/Footer/Footer";
import Mapandform from "../../../Components/mapandform/Mapandform";
const Services = () => {
  return (
    <>
      <div className="hero-section-navbar-sectionpage">
        <ServicesNavbar />
        <section>
          <div className="services-main">
            <div className="class">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="titlepage ">
                      <center>
                        <h2 style={{ marginBottom: "17px" }}>Our Services</h2>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 margi_bottom">
                    <div className="class_box text_align_center">
                      <h3 style={{ textAlign: "center" }}>
                        UI/UX Designing Services:
                      </h3>
                      <p style={{ textAlign: "center" }}>
                        "Transforming your vision into captivating UI designs
                        that resonate with your audience. Our meticulous process
                        ensures every pixel reflects your brand's essence,
                        creating an immersive user experience. Elevate your
                        digital presence with our bespoke UI/UX design services
                        tailored to your unique needs."{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 margi_bottom">
                    <div className="class_box blue text_align_center">
                      <h3 style={{ textAlign: "center" }}>Web Development</h3>
                      <p style={{ textAlign: "center" }}>
                        "Explore advanced web development merging top industry
                        standards with budget-friendly technologies. With ex
                        pertise in React and Node.js, we create sleek,
                        responsive websites for a dynamic online presence. From
                        front-end design to back-end functionality, our tailored
                        solutions elevate your business online."{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 margi_bottom">
                    <div className="class_box text_align_center">
                      <h3 style={{ textAlign: "center" }}>SEO Optimization</h3>
                      <p style={{ textAlign: "center" }}>
                        "At Eastechy IT Solutions, we specialize in elevating
                        your online visibility through expert SEO optimization
                        strategies. Our tailored approach ensures your website
                        ranks higher in search engine results, driving increased
                        traffic and conversions. Trust our team to optimize your
                        digital presence and unlock your business's full
                        potential.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 margi_bottom">
                    <div className="class_box blue text_align_center">
                      <h3 style={{ textAlign: "center" }}>
                        E-Commerce Solutions
                      </h3>
                      <p style={{ textAlign: "center" }}>
                        "we offer custom and tailored e-commerce solutions
                        designed for businesses of all sizes, featuring advanced
                        functionalities such as order tracking and management
                        systems. Our goal is to streamline operations and
                        enhance customer experiences, ensuring seamless
                        e-commerce functionality at any scale."{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 margi_bottom">
                    <div className="class_box  text_align_center">
                      <h3 style={{ textAlign: "center" }}>
                        {" "}
                        Social Media Managment
                      </h3>
                      <p style={{ textAlign: "center" }}>
                        "We, as an experienced corporate entity, maintain your
                        social media presence across all major platforms
                        including Facebook, Instagram, LinkedIn, Twitter,
                        Pinterest, and more. With consistent and proactive
                        management, we ensure daily updates to keep your
                        audience engaged and your brand thriving."{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 margi_bottom">
                    <div className="class_box blue  text_align_center">
                      <h3 style={{ textAlign: "center" }}>
                        {" "}
                        Digital Marketing
                      </h3>
                      <p style={{ textAlign: "center" }}>
                        "Elevate your brand with our data-driven digital
                        marketing solutions, including SEO, SEM, social media
                        marketing, content creation, email campaigns, and
                        detailed analytics. We specialize in high DA link
                        building to boost traffic and leads, ensuring your
                        online success."{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <>
        <Mapandform />
      </>
      <>
        <Footer />
      </>
    </>
  );
};

export default Services;
