import React from "react";
import "./Aboutus.css";
import lineDec from "../../images/heading-line-dec.png";
import about from "../../images/about-right-dec.png";
const Aboutus = () => {
  return (
    <>
      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="section-heading">
                <h4 className="about-heading">
                  <em>About</em> &amp; Portfolio
                </h4>
                <img src={lineDec} alt />
                <p style={{ textIndent: "40px", textAlign: "justify" }}>
                  With a distinguished track record spanning over 7 years,
                  Eastechy stands out as a premier design and development
                  agency. Specializing in industry-best services such as SEO,
                  SEM, content writing, and corporate application development,
                  we have earned the trust of over 37+ clients in the market.
                  Our expertise extends to maintaining highly secure databases,
                  ensuring the utmost confidentiality and reliability for our
                  clients' valuable information. At Eastechy, we are committed
                  to delivering cutting-edge solutions and consistently
                  exceeding expectations in the ever-evolving digital landscape.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4 style={{ fontSize: "20px" }}>Social Media Managment</h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4 style={{ fontSize: "20px" }}>E-Commerce Solutions</h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4 style={{ fontSize: "20px" }}>Digital Marketing</h4>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4 style={{ fontSize: "20px" }}>
                      Training & Placement Solutions
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-image">
                <img src={about} alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
