import React from "react";
import "./Servicesbody.css";
const Servicesbody = () => {
  return (
    <>
      <div id="services" className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div
                className="section-heading wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <h4>
                  Amazing <em>Services &amp; Features</em> for you
                </h4>
                <img src="assets/images/heading-line-dec.png" alt />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="service-item first-service">
                <div className="icon" />
                <h4 style={{ textAlign: "center" }}>App Maintenance</h4>
                <p style={{ textAlign: "center" }}>
                  Continuous optimization of existing features, functionalities,
                  and services involves regular updates tailored to user
                  expectations. This iterative process ensures alignment with
                  evolving user needs, enhancing overall satisfaction and
                  usability.
                </p>
                <div className="text-button" style={{ textAlign: "center" }}>
                  <a href="#">
                    Read More <i className="fa fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item second-service">
                <div className="icon" />
                <h4 style={{ textAlign: "center" }}>Traffic Generation</h4>
                <p style={{ textAlign: "center" }}>
                  Drive traffic through SEO, SEM, and online marketing,
                  optimizing content and bidding on keywords. Create multiple
                  quality blogs, focusing on regular publishing, keyword
                  optimization, and cross-channel promotion
                </p>
                <div className="text-button" style={{ textAlign: "center" }}>
                  <a href="#">
                    Read More <i className="fa fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item third-service">
                <div className="icon" />
                <h4 style={{ textAlign: "center" }}>Bulk Blogging</h4>
                <p style={{ textAlign: "center" }}>
                  Bulk blogging involves creating multiple blog posts at once,
                  streamlining content creation and scheduling. It's an
                  efficient strategy for maintaining a consistent online
                  presence and engaging with your audience regularly.
                  <br></br>
                  <br></br>
                </p>
                <div className="text-button" style={{ textAlign: "center" }}>
                  <a href="#">
                    Read More <i className="fa fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item fourth-service">
                <div className="icon" />
                <h4 style={{ textAlign: "center" }}>Online Marketing</h4>
                <p style={{ textAlign: "center" }}>
                  We promote pages, websites, products, or services through
                  comprehensive online marketing strategies, utilizing platforms
                  like Facebook, Instagram, LinkedIn, and Google Ads, among
                  others.
                  <br></br>
                  <br></br>
                  <br></br>
                </p>
                <div className="text-button" style={{ textAlign: "center" }}>
                  <a href="#">
                    Read More <i className="fa fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servicesbody;
