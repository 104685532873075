import React from "react";
import s1 from "../../images/s1.png";
import s2 from "../../images/s2.png";
import s3 from "../../images/s3.png";
import Ecommerce from "../../images/tormarch26.png";
import SocialMedia from "../../images/4950546.png";
import DigitalMarketing from "../../images/5476534.png";
import WebDevelopment from "../../images/4300580.png";
import "../../App.css";
import aboutus from "../../images/2950171.png";
import laedGenerationVector from "../../images/lead_generation_vector.png";

// import { useTypewriter, Cursor } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import Mapandform from "../../Components/mapandform/Mapandform";
import logo from "../../images/logo.png";
import Testimonial from "../../Components/Testimonials/Testimonial";
import Footer from "../../Components/Footer/Footer";
import Servicesbody from "../../Components/ServicesBody/Servicesbody";
import Aboutus from "../../Components/Aboutus/Aboutus";
const App = () => {
  // const [text] = useTypewriter({
  //   words: [
  //     "Responsive UI Design",
  //     "Custom Web Development",
  //     "Enterprise Application Development",
  //     "SEO & SEM",
  //     "Digital Marketing",
  //     "E-Commerce Solutions",
  //     "API integration",
  //   ],
  //   loop: Infinity,
  // });
  return (
    <>
      <div className="hero_area">
        {/* header section strats */}
        <header className="header_section">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg custom_nav-container ">
              <img
                src={logo}
                width={"90vh"}
                alt="eastechy-logo"
                className="eastechy-logo"
              />
              <a className="navbar-brand" href="/">
                <span className="anta-regular-main">EASTECHY</span>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className=""> </span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav  ">
                  <li className="nav-item active">
                    <a className="nav-link" href="/">
                      Home <span className="sr-only">(current)</span>
                    </a>
                  </li>{" "}
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      {" "}
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link to="/services" className="nav-link">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#Contact-us-in">
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div
                  className="quote_btn-container"
                  style={{ marginRight: "2px" }}
                >
                  <a href="tel:6281967624" className="quote_btn">
                    Get A Quote
                  </a>
                </div>{" "}
                <div className="quote_btn-container">
                  <a href="tel:6281967624" className="quote_btn_callnow ">
                    Call Now
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </header>
        {/* end header section */}
        {/* slider section */}
        <section className="slider_section ">
          <div
            id="customCarousel1"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="img-box">
                        <img src={Ecommerce} alt="eastechy-main-hero-img-2" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="detail-box">
                        <h1 style={{ fontSize: "4.5vh" }}>
                          E-Commerce solutions
                        </h1>
                        <p>
                          customized e-commerce applications for a concise and
                          comprehensive online presence include secure payment
                          processing via Razorpay,PayPal and Stripe, enhanced by
                          SSL certificates and fraud prevention measures. We
                          optimize inventory management, streamline order
                          processing, and seamlessly integrate shipping
                          logistics for efficiency. Committed to customer
                          satisfaction, our services feature CRM systems,
                          mobile-optimized design, and detailed analytics. With
                          a strong focus on scalability, compliance, we also
                          specialize in developing
                        </p>
                        <div className="btn-box">
                          <Link to={"/services"} className="btn1">
                            <span>Read More </span>
                            <i
                              className="fa fa-long-arrow-right"
                              aria-hidden="true"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="img-box">
                        <img
                          src={WebDevelopment}
                          alt="eastechy-main-hero-img"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="detail-box">
                        <h1 style={{ fontSize: "4.5vh" }}>Web Development</h1>
                        <p>
                          Enhance your online footprint with our seasoned team,
                          providing precise solutions across web and mobile app
                          development. We go beyond captivating UI/UX design,
                          extending our expertise to front-end API development,
                          robust backend solutions, and more. Collaborate with
                          us for comprehensive services that reshape online
                          experiences, encompassing end-to-end development,
                          backend architecture, and other crucial aspects to
                          guarantee your success in the digital realm.
                        </p>
                        <div className="btn-box">
                          <Link to={"/services"} className="btn1">
                            <span>Read More </span>
                            <i
                              className="fa fa-long-arrow-right"
                              aria-hidden="true"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item ">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="img-box">
                        <img src={SocialMedia} alt="eastechy-main-hero-img" />{" "}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="detail-box">
                        <h1 style={{ fontSize: "4.5vh" }}>
                          Social Media Managment
                        </h1>
                        <p>
                          "Unlock your brand's potential with our expert social
                          media team. From engaging posts to strategic
                          campaigns, we've got you covered. Elevate your online
                          presence today! #SocialMediaManagement
                          #ContentCreation"
                        </p>
                        <div className="btn-box">
                          <Link to={"/services"} className="btn1">
                            <span>Read More </span>
                            <i
                              className="fa fa-long-arrow-right"
                              aria-hidden="true"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Digital Marketing */}
              <div className="carousel-item ">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="img-box">
                        <img
                          src={DigitalMarketing}
                          alt="slier-gb-eastechy-main-inside-hero"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="detail-box">
                        <h1 style={{ fontSize: "4.5vh" }}>Digital Marketing</h1>
                        <p>
                          Empower your brand with our data-driven digital
                          marketing solutions. We harness the power of advanced
                          SEO tools, providing services such as Search Engine
                          Optimization (SEO), Search Engine Marketing (SEM),
                          social media marketing, content creation, email
                          campaigns, and in-depth analytics. Our comprehensive
                          approach ensures online success by effectively
                          reaching and engaging your target audience.
                        </p>
                        <div className="btn-box">
                          <Link to={"/services"} className="btn1">
                            <span>Read More </span>
                            <i
                              className="fa fa-long-arrow-right"
                              aria-hidden="true"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ol className="carousel-indicators">
              <li
                data-target="#customCarousel1"
                data-slide-to={0}
                className="active"
              />
              <li data-target="#customCarousel1" data-slide-to={1} />
              <li data-target="#customCarousel1" data-slide-to={2} />
              <li data-target="#customCarousel1" data-slide-to={3} />
            </ol>
          </div>
        </section>
        {/* end slider section */}
      </div>
      <>
        <Servicesbody />
      </>
      <br />
      {/* service section */}
      <div className="service_section">
        <div style={{ marginTop: "1vh" }}></div>
        {/* <center>
          <h3 style={{ fontSize: "20px", marginTop: "5vh" }}>
            We are a team of young, dynamic and go-getter Developers
            <br />
            We Provide Industry Best{" "}
            <span style={{ color: "orange", fontSize: "17px" }}>{text}</span>
            <Cursor cursorColor="red" />
          </h3>
        </center> */}
      </div>
      {/* end service section */}
      <>
        <Aboutus />
      </>

      {/* end case section */}
      {/* client section */}
      <Testimonial />
      {/* end client section */}
      <>
        <Mapandform />
      </>
      <>
        <Footer />
      </>
    </>
  );
};

export default App;
