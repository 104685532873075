import React from "react";
import client from "../../images/client.jpg";

const Testimonial = () => {
  return (
    <>
      {/* client section */}
      <section className="client_section ">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Testimonie's</h2>
          </div>
        </div>
        <div
          id="customCarousel2"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="box">
                      <div className="img-box">
                        <img src={client} alt="" />
                      </div>
                      <div className="detail-box">
                        <div className="client_info">
                          <div className="client_name">
                            <h5>Sai Alpha Resources</h5>
                            <h6>Customer</h6>
                          </div>
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                        <p>
                          "Working with EasTechy IT Solutions has been a
                          game-changer for our business. Their expertise in both
                          software development and digital marketing has truly
                          elevated our online presence and streamlined our
                          operations"
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="box">
                      <div className="img-box">
                        <img src={client} alt="" />
                      </div>
                      <div className="detail-box">
                        <div className="client_info">
                          <div className="client_name">
                            <h5>Sanjeev Sky Blue Beverages</h5>
                            <h6>Customer</h6>
                          </div>
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                        <p>
                          " Their digital marketing strategies have
                          significantly boosted our online visibility and
                          engagement. Their comprehensive approach,
                          incorporating SEO, social media marketing, and
                          targeted advertising, has resulted in measurable
                          growth in our website traffic and conversions."
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="box">
                      <div className="img-box">
                        <img src={client} alt="" />
                      </div>
                      <div className="detail-box">
                        <div className="client_info">
                          <div className="client_name">
                            <h5>Kishore Recpro Solutions</h5>
                            <h6>Customer</h6>
                          </div>
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                        <p>
                          " Thanks to EasTechy IT Solutions, we've been able to
                          scale our business and reach new heights in the
                          digital landscape. I highly recommend their services
                          to any company looking to harness the power of
                          technology and digital marketing to drive success."
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="box">
                      <div className="img-box">
                        <img src={client} alt="" />
                      </div>
                      <div className="detail-box">
                        <div className="client_info">
                          <div className="client_name">
                            <h5>Naresh (NV Oragnic Mart)</h5>
                            <h6>Customer</h6>
                          </div>
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                        <p>
                          " Thanks to the exceptional services provided by
                          EasTechy IT Solutions, we've successfully expanded our
                          business and achieved remarkable growth in the digital
                          realm. I enthusiastically endorse their services to
                          any company seeking to leverage technology and digital
                          marketing for driving success."
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="box">
                      <div className="img-box">
                        <img src={client} alt="" />
                      </div>
                      <div className="detail-box">
                        <div className="client_info">
                          <div className="client_name">
                            <h5>Vasavi (Vasavi Natural Mart)</h5>
                            <h6>Customer</h6>
                          </div>
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                        <p>
                          " their digital marketing expertise has been nothing
                          short of transformative. Through strategic SEO
                          tactics, captivating social media campaigns, and
                          precision-targeted advertising, Eastechy has
                          significantly amplified our online presence, driving
                          unprecedented levels of traffic and engagement to our
                          platforms."
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ol className="carousel-indicators">
            <li
              data-target="#customCarousel2"
              data-slide-to={0}
              className="active"
            />
            <li data-target="#customCarousel2" data-slide-to={1} />
            <li data-target="#customCarousel2" data-slide-to={2} />
            <li data-target="#customCarousel2" data-slide-to={3} />
            <li data-target="#customCarousel2" data-slide-to={4} />
          </ol>
        </div>
      </section>
      {/* end client section */}
    </>
  );
};

export default Testimonial;
